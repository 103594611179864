<template>
  <section v-if="loaded && gameCount">
    <transition name="fade">
      <div class="loading" v-if="!banner">
        <!--img src="@/assets/img/loading.gif" /-->
      </div>
    </transition>
    <!--button class="dropdown" :value="categoryName" @click="aselect=!aselect">{{ categoryName }}</button-->
    <div class="aselect">
    </div>
    <!--
    transition name="fade">
      <ui-swiper v-if="banner"/>
    </transition>
    -->
   <div>
      <section class="mbanner">
         <img src="@/assets/img/main/main_banner01.png" />
      </section>
      <section class="gameList">
        <ul class="main-menu">
          <li @click="goPageByName('listcasino')"><img src="../assets_mobile/img/icon/menu-poker.svg">라이브카지노</li>
          <!-- <li @click="goPageByName('listhotel')"><img src="../assets_mobile/img/icon/menu-hotel.svg">호텔카지노</li> -->
          <li @click="goPageByName('listslot')"><img src="../assets_mobile/img/icon/menu-slot.svg">슬롯게임</li>
          <!-- <li @click="goPageByName('listmini')"><img src="../assets_mobile/img/icon/menu-mini.svg">미니게임</li> -->
          <!-- <li @click="goPageByName('listsports')"><img src="../assets_mobile/img/icon/menu-sports.svg">스포츠</li> -->
          <li @click="goPageByName('betList')"><img src="../assets_mobile/img/icon/menu-board.svg">베팅내역</li>
          <li @click="goPageByName('noticeList')"><img src="../assets_mobile/img/icon/menu-notice.svg">공지사항</li>
          <li @click="goPageByName('csMain')"><img src="../assets_mobile/img/icon/menu-cs.svg">고객센터</li>
          <li @click="goPageByName('info')"><img src="../assets_mobile/img/icon/menu-set.svg">마이페이지</li>
        </ul>
      </section>
      <!-- <section>
        <h2 class="mainnames">
           <img src="@/assets_mobile/img/icon_m_notice.svg" />
           {{ $t('front.common.notice') }}
           <a class="more" @click="goPageByName('noticeList')"><img src="@/assets_mobile/img/icon_m_arrow.svg" /></a>
        </h2>
        <div class="servicecenter">
         <ul @click="goPageByName('noticeList')">
            <template v-for="item in noticeList" :key="item">
             <li>
                <i><img src="@/assets_mobile/img/icon_bell.svg" /></i>
                <span><em>{{item.regDate}}</em>{{item.title}}</span>
             </li>
            </template>
            <template v-for="item in noticeListMaxCount - noticeList.length" :key="item">
             <li>
                <span><em>&nbsp;</em>&nbsp;</span>
             </li>
            </template>
         </ul>
        </div>
      </section>
      <section>
         <h2 class="mainnames">
            <img src="@/assets_mobile/img/icon_m_inout.svg" />
            {{ $t('front.main.livecharge') }}
         </h2>
         <div class="inoutwrap">
            <div class="btn">
               <a @click="onChangeType('in')" :class="{'active': type === 'in'}">{{ $t('front.main.livecharge') }}</a>
               <a @click="onChangeType('out')" :class="{'active': type === 'out'}">{{ $t('front.main.liveExchange') }}</a>
            </div>
            <div v-if="type == 'in'" class="inout">
               <ul>
                 <template v-for="item in cashInList" :key="item.memId">
                   <li>
                     <span><img src="@/assets/img/icon_inin.svg" />{{item.memId}}</span>
                     <span>{{thousand(Number(item.cashAmt))}} 원</span>
                     <span>{{item.regDate}}</span>
                   </li>
                 </template>
                 <template v-for="item in listMaxCount - cashInList.length" :key="item">
                 </template>
               </ul>
            </div>
            <div v-if="type == 'out'" class="inout">
               <ul>
                 <template v-for="item in cashOutList" :key="item.memId">
                   <li>
                     <span><img src="@/assets/img/icon_outout.svg" />{{item.memId}}</span>
                     <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                     <span>{{item.regDate}}</span>
                   </li>
                 </template>
                 <template v-for="item in listMaxCount - cashOutList.length" :key="item">
                 </template>
               </ul>
            </div>
         </div>
      </section> -->
   </div>
  </section>
  <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
    <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
      <div class="closeWrap">
        <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
        <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
      </div>
      <img :src="item.bannerImg" />
    </div>
  </template>

</template>

<script>

import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'
import { getSlotGame } from '@/api/game'
import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { isMobile } from '@/libs/utils'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'MIndex',
  components: {
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'commonCodeByCode',
      'popup',
      'popupLogin',
      'banner',
      'gameCount',
      'siteOption'
    ])
  },
  data () {
    return {
      type: 'in',
      categoryName: this.$t('front.gameCategory.livecasino'),
      loaded: false,
      currentTab: 'tab-0',
      gameList: [],
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      popupList: {},
      listMaxCount: 3,
      noticeListMaxCount: 5
    }
  },
  watch: {
    async commonCodeByCode () {
      await this.gameLoad()
    },
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  methods: {
    selectCategory (text, tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab
      } else {
        // this.currentTab = ''
      }
      this.categoryName = text
    },
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        if (image) {
          return image
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets_mobile/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    slotOpen (event, groupCode, codeName) {
      console.log(event, groupCode, codeName)
      this.emitter.emit(event, { groupCode, codeName })
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
      console.log(this.popupList)
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    onChangeType (type) {
      this.type = type
    },
    gameLoad () {
      this.loadMain()
      this.loadNotice()
      this.loadCashStatus()

      const gameList = JSON.parse(JSON.stringify(this.commonCodeByCode))

      for (const item in gameList) {
        const game = gameList[item]
        let type = 'type2'
        if (game.groupCode === 'casino') {
          type = 'tab-2'
          if (game.groupCodeName.indexOf('HC-') > -1) {
            type = 'tab-3'
          }
        } else if (game.groupCode === 'slot') {
          type = 'tab-4'
          if (game.groupCodeName.indexOf('H-') > -1) {
            type = 'tab-7'
          }
        } else if (game.groupCode === 'minigame') {
          type = 'tab-6'
        } else if (game.groupCode === 'sport') {
          type = 'tab-5'
        }
        game.type = type
        this.gameList.push(game)
      }

      this.loaded = true

      if (this.$route.params.prodId && this.$route.params.type) {
        if (this.userData && this.userData.kplayId) {
          const params = {
            kplayId: this.userData.kplayId,
            gameCode: this.$route.params.prodId,
            gameGroupCode: 'slot',
            type: this.$route.params.type,
            prodId: this.$route.params.prodId
          }
          getSlotGame(params)
            .then(response => {
              const result = response.data
              if (result.resultCode === '0') {
                const data = result.data
                const gameUrl = data.gameurl

                const _width = 1100
                const _height = 680
                const _left = Math.ceil((window.screen.width - _width) / 2)
                const _top = 100
                if (isMobile()) {
                  // mobile
                  const p = window.open('/', '_blank')
                  p.location.href = gameUrl
                } else {
                  // pc
                  window.open(gameUrl, 'evolution', `width=${_width},height=${_height},left=${_left},top=${_top}`)
                }
              }
            })
        } else {
          this.onCheck('front.error.afterSignin')
        }
      }
    }
  },
  async created () {
    await this.loadMain()
    await this.loadNotice()
    await this.loadCashStatus()
    this.gameLoad()
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    next()
  }
}
</script>
<style scoped>
.loading {z-index: 10;position: relative;width: 100%;height: 78px;display:flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.mainPop {position: relative;min-width: 320px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background: #fff;border: 1px solid #1c594e;z-index: 100;}
.mainPop .closeWrap {width: 100%;display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 320px;height: 440px;vertical-align: bottom;object-fit: cover;object-position: center;}
</style>
<style>
.mainSlide img{width:100%;height: 200px;object-fit: cover;object-position: center;}
.swiper-button-next {background-image: url('~@/assets/img/slideright.png') !important;background-size: 11px 20px !important;}
.swiper-button-prev {background-image: url('~@/assets/img/slideleft.png') !important;background-size: 11px 20px !important;}
</style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/main.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
